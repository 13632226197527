import {
  Button,
  Checkbox,
  FormGroup,
  InputGroup,
  NumericInput,
} from "@blueprintjs/core";
import {
  ChartSoundSource,
  EditorDeleteSourceDocument,
  EditorUpdateSourceFileDocument,
  EditorUpdateSourceVolumeDocument,
  EditorUpdateSourceAmbientDocument,
  UpdateSoundShapeRadial2DDocument,
  EditorUpdateSourceAltitudeDocument,
  EditorUpdateSourceKindDocument,
  AssetSoundFileStatusEnum,
} from "../../../../../../__generated__/gql/graphql";
import { useArea } from "../../../../contexts/AreaContext";
import {
  SelectionType,
  useSelection,
} from "../../../../contexts/SelectionContext";
import PanelContainer from "../../PanelContainer";
import { useMutation } from "@apollo/client";
import destination from "@turf/destination";
import FileSelect, {
  FileSelectOption,
} from "../../../../components/FileSelect";
import { FadeEditor } from "./FadeEditor";
import SourceKindSelect from "../../../../components/SourceKindSelect";
import { ChangeEvent } from "react";

type SelectedSoundSourceBodyProps = {
  source: ChartSoundSource;
};

// FIXME 
enum ChartSoundSourceKindEnum {
  Fixed = "fixed",
  Passthrough = "passthrough",
  Ambisonic = "ambisonic",
};

const FILE_FILTERS: Record<ChartSoundSourceKindEnum, (file: FileSelectOption) => boolean> = {
  [ChartSoundSourceKindEnum.Fixed]: (file: FileSelectOption) => (file.status === AssetSoundFileStatusEnum.Ready && file.channels && file.channels === 1) || false,
  [ChartSoundSourceKindEnum.Passthrough]: (file: FileSelectOption) => (file.status === AssetSoundFileStatusEnum.Ready && file.channels && file.channels === 2) || false,
  [ChartSoundSourceKindEnum.Ambisonic]: (file: FileSelectOption) => (file.status === AssetSoundFileStatusEnum.Ready && file.channels && [4, 9, 16, 25, 36].includes(file.channels)) || false,
};

function SelectedSoundSourceBody({ source }: SelectedSoundSourceBodyProps) {
  const [updateRadius] = useMutation(UpdateSoundShapeRadial2DDocument);
  const [updateVolume] = useMutation(EditorUpdateSourceVolumeDocument);
  const [updateAltitude] = useMutation(EditorUpdateSourceAltitudeDocument);
  const [updateAmbient] = useMutation(EditorUpdateSourceAmbientDocument);
  const [updateFile] = useMutation(EditorUpdateSourceFileDocument);
  const [deleteSource] = useMutation(EditorDeleteSourceDocument);
  const [updateKind] = useMutation(EditorUpdateSourceKindDocument);

  // TODO detect shape
  // or move origin from shape to sound source
  const handleRadiusChange = (valueAsNumber: number) => {
    if (valueAsNumber > 0) {
      const delta = valueAsNumber - source.shape.radius;
      const newOrigin = destination(
        [source.origin.globalLongitude, source.origin.globalLatitude],
        Math.abs(delta) / 1000.0,
        delta > 0 ? -45 : 135,
      );

      updateRadius({
        variables: {
          id: source.id,
          radius: valueAsNumber,
          globalLongitude: newOrigin.geometry.coordinates[0],
          globalLatitude: newOrigin.geometry.coordinates[1],
        },
      });
    }
  };

  const handleVolumeChange = (valueAsNumber: number) => {
    if (valueAsNumber >= 0 && valueAsNumber <= 1) {
      updateVolume({
        variables: {
          id: source.id,
          volume: valueAsNumber,
        },
      });
    }
  };

  const handleAltitudeChange = (valueAsNumber: number) => {
    updateAltitude({
      variables: {
        id: source.id,
        altitude: valueAsNumber,
      },
    });
  };

  const handleAmbientChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    updateAmbient({
      variables: {
        id: source.id,
        ambient: event.target.checked,
      },
    });
  };

  const handleFileSelect = (file: FileSelectOption) => {
    updateFile({
      variables: {
        id: source.id,
        fileId: file.id,
      },
    });
  };

  const handleKindSelect = (e: ChangeEvent<HTMLSelectElement>) => {
    updateKind({
  		variables: {
        id: source.id,
        kind: e.target.value,
      },
  	});
  };

  return (
    <div>
      <div>ID: {source.id}</div>

      <FormGroup inline label="Kind" labelFor="Selected-SoundSource-kind">
        <SourceKindSelect 
          id="Selected-SoundSource-kind"
          value={source.kind} 
          onChange={handleKindSelect} 
        />
      </FormGroup>

      {source.kind !== ChartSoundSourceKindEnum.Fixed && (
        <FormGroup inline label="Ambient" labelFor="Selected-SoundSource-ambient">
          <Checkbox
            id="Selected-SoundSource-ambient"
            checked={source.ambient}
            onChange={handleAmbientChange}
          />
        </FormGroup>
      )}

      {!source.ambient && (
        <>
          <FormGroup
            inline
            label="Longitude"
            labelFor="Selected-SoundSource-globalLongitude"
          >
            <InputGroup
              id="Selected-SoundSource-globalLongitude"
              value={`${source.origin.globalLongitude}`}
            />
          </FormGroup>

          <FormGroup
            inline
            label="Latitude"
            labelFor="Selected-SoundSource-globalLatitude"
          >
            <InputGroup
              id="Selected-SoundSource-globalLatitude"
              value={`${source.origin.globalLatitude}`}
            />
          </FormGroup>

          <FormGroup
            inline
            label="Radius"
            labelFor="Selected-SoundSource-radius"
          >
            <NumericInput
              id="Selected-SoundSource-radius"
              min={0.1}
              defaultValue={source.shape?.radius}
              onValueChange={handleRadiusChange}
            />
          </FormGroup>

          <FormGroup
            inline
            label="Altitude"
            labelFor="Selected-SoundSource-altitude"
          >
            <NumericInput
              id="Selected-SoundSource-altitude"
              minorStepSize={0.01}
              stepSize={0.05}
              defaultValue={source.altitude}
              onValueChange={handleAltitudeChange}
            />
          </FormGroup>

          <FadeEditor source={source} />
        </>
      )}

      <FormGroup inline label="Volume" labelFor="Selected-SoundSource-volume">
        <NumericInput
          id="Selected-SoundSource-volume"
          min={0.0}
          max={1.0}
          minorStepSize={0.01}
          stepSize={0.05}
          defaultValue={source.volume}
          onValueChange={handleVolumeChange}
        />
      </FormGroup>

      <FormGroup inline label="File" labelFor="Selected-SoundSource-file">
        <FileSelect 
          selected={source.file} 
          onSelect={handleFileSelect} 
          filter={FILE_FILTERS[source.kind as ChartSoundSourceKindEnum]} />
      </FormGroup>

      <Button
        text="Delete"
        intent="danger"
        onClick={() => {
          deleteSource({ variables: { id: source.id } });
        }}
      />
    </div>
  );
}

export default function SelectedPanel() {
  const { selectionType, selectionId } = useSelection();
  const { area } = useArea();

  switch (selectionType) {
    case SelectionType.ChartAnnotation:
      return <div />;

    case SelectionType.ChartSoundSource:
      const source = area.sources.find((source) => source.id === selectionId);
      if (!source) return <div />;

      return (
        <PanelContainer title={`Sound Source: ${source.name}`}>
          <SelectedSoundSourceBody source={source} />
        </PanelContainer>
      );

    default:
      return <div />;
  }
}
